import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import Tables from "../components/tables";
import { deletePackage, getPackageAll } from "../redux/actions/package";
// import InfiniteScroll from "react-infinite-scroll-component";

class packageAll extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies),
  };
  constructor(props) {
    super(props);
    this.state = {
      rows: [
        { label: "No", value: "no", align: "center" },
        { label: "Name", value: "name", align: "center" },
        { label: "Description", value: "description", align: "center" },
        { label: "Price", value: "price", align: "center" },
        { label: "Qty Print", value: "qty_print", align: "center" },
        { label: "Status", value: "is_active", align: "center" },
        { label: "Action", value: "action", align: "center" },
      ],
      perpage: [30, 50, 100, 250, 500, 1000],
      orderBy: [
        { id: "name", name: "Name" },
        { id: "is_active", name: "Status" },
      ],
      sortBy: [
        { id: "ASC", name: "Ascending" },
        { id: "DESC", name: "Descending" },
      ],
      data: [],
      hasMore: false,
      filter_perpage: 0,
      filter_order: "",
      filter_sort: "",
      filter_name: "",
      is_loading: false,
      page: 1,
    };
  }

  columns = (type, value, data, key) => {
    switch (type) {
      case "no":
        value = key + 1;
        break;
      case "is_active":
        if (value) {
          value = "Aktif";
        } else {
          value = "Tidak Aktif";
        }
        break;
      case "action":
        value = (
          <Stack
            direction={"row"}
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Button
              variant="contained"
              color="warning"
              disabled={
                this.props.permissions.indexOf("edit package") !== -1
                  ? false
                  : true
              }
              onClick={(e) => {
                this.props.history.push(
                  this.props.match.url + "/edit/" + data.slug
                );
              }}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              color="error"
              data-id={data.id}
              disabled={
                this.props.permissions.indexOf("delete package") !== -1
                  ? false
                  : true
              }
              onClick={this.handleDelete}
            >
              Delete
            </Button>
          </Stack>
        );
        break;
      default:
        value = <>{value}</>;
        break;
    }
    return value;
  };

  params = () => {
    let params = {};

    if (this.state.filter_name !== "") {
      params.name = this.state.filter_name;
    }
    if (this.state.filter_order !== "") {
      params.order = this.state.filter_order;
    }
    if (this.state.filter_sort !== "") {
      params.sort = this.state.filter_sort;
    }

    params.perpage = this.state.perpage[this.state.filter_perpage];
    params.page = 1;
    params.is_active = 1;

    return params;
  };

  loadData = async () => {
    let params = this.params();
    const request = await this.props.getPackageAll(params);
    if (request.code === 200) {
      this.setState({
        data: request.data,
        hasMore:
          this.state.perpage[this.state.filter_perpage] !== request.data.length
            ? false
            : true,
        is_loading: false,
      });
    } else {
      this.setState({
        data: [],
        hasMore: false,
        is_loading: false,
      });
    }
  };

  fetchCookies = () => {
    var package_cookies = this.props.cookies.get("package_cookies");
    if (package_cookies !== undefined) {
      this.setState(
        {
          filter_perpage: package_cookies.filter_perpage,
          filter_order: package_cookies.filter_order,
          filter_sort: package_cookies.filter_sort,
          filter_name: package_cookies.filter_name,
        },
        () => {
          this.buttonElement.click();
        }
      );
    }
  };

  handleChangeFilter = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFilterSubmit = (e) => {
    this.setState({ is_loading: true });
    e.preventDefault();

    const { cookies } = this.props;
    let expires = new Date();
    let data = {
      filter_perpage: this.state.filter_perpage,
      filter_order: this.state.filter_order,
      filter_sort: this.state.filter_sort,
      filter_name: this.state.filter_name,
    };
    expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000);

    cookies.set("package_cookies", data, { path: "/", expires: expires });

    this.loadData();
  };

  handleFilterClear = (e) => {
    this.setState(
      {
        filter_perpage: 0,
        filter_order: "",
        filter_sort: "",
        filter_name: "",
      },
      async function () {
        await this.props.cookies.remove("package_cookies", {
          path: "/",
          expires: 0,
        });
        await this.loadData();
      }
    );
  };

  handleDelete = async (e) => {
    e.preventDefault();
    const confirmed = window.confirm("Are you sure to delete this package?");
    if (confirmed) {
      const request = await this.props.deletePackage({
        id: e.target.dataset.id,
      });
      if (request.code === 200) {
        window.location.reload();
        alert("Delete Success");
      } else {
        alert("Something Error : " + request.message);
      }
    }
  };

  loadFunc = async () => {
    const ctx = this;
    let params = this.params();
    params.page = this.state.page + 1;
    const request = await this.props.getPackageAll(params);
    if (request.code === 200) {
      if (request.data !== null) {
        setTimeout(() => {
          ctx.setState({
            data: this.state.data.concat(request.data),
            hasMore:
              request.data.length !== this.state.perpage[this.state.filter_perpage]
                ? false
                : true,
            page: this.state.page + 1,
          });
        }, 1500);
      } else {
        ctx.setState({ hasMore: false, page: this.state.page + 1 });
      }
    } else {
      ctx.setState({ hasMore: false, page: this.state.page + 1 });
    }
  };

  componentDidMount() {
    this.loadData();
    this.fetchCookies();
  }

  componentDidUpdate(prevProv, prevState) {
    if (prevProv.cookies !== this.props.cookies) {
      this.fetchCookies();
    }
  }

  render() {
    const {
      rows,
      data,
      hasMore,
      filter_name,
      filter_perpage,
      perpage,
      filter_order,
      orderBy,
      is_loading,
    } = this.state;
    const { title, permissions, history, match } = this.props;

    return (
      <Container className="main-panel booth">
        <Grid container className="header-panel" alignItems="center" mb={1}>
          <Grid item md={6} xs={6} sx={{ justifyContent: "flex-start" }}>
            <Typography variant="h6">{!title ? "" : title}</Typography>
          </Grid>
          <Grid item md={6} xs={6} sx={{ textAlign: "right" }}>
            <Stack direction={"row"} spacing={2} justifyContent="flex-end">
              {permissions.indexOf("create package") !== -1 ? (
                <Button
                  alt="Test Experimental"
                  variant="contained"
                  color="primary"
                  sx={{ marginRight: "10px" }}
                  onClick={(e) => {
                    history.push(match.url + "/add");
                  }}
                >
                  Add Package
                </Button>
              ) : null}
            </Stack>
          </Grid>
        </Grid>
        <Grid
          container
          className="header-panel"
          alignItems="center"
          sx={{
            paddingTop: "10px",
            paddingBottom: "10px",
            marginBottom: "10px",
            gap: "20px",
          }}
        >
          <Grid item md={2} xs={12}>
            <TextField
              size="small"
              id="input-name"
              name="filter_name"
              label="Name"
              placeholder="Filter Name"
              onChange={this.handleChangeFilter}
              value={filter_name}
              sx={{ width: "100%" }}
            ></TextField>
          </Grid>
          <Grid item md={2} xs={12}>
            <FormControl size="small" sx={{ width: "100%" }}>
              <InputLabel id="select-perpage-label">Per Page</InputLabel>
              <Select
                size="small"
                label="Perpage"
                name="filter_perpage"
                id="select-perpage"
                labelId="select-perpage-label"
                onChange={this.handleChangeFilter}
                value={filter_perpage}
                sx={{ width: "100%" }}
              >
                {perpage.map((item, idx) => {
                  return (
                    <MenuItem value={idx} key={idx}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={2} xs={12}>
            <FormControl size="small" sx={{ width: "100%" }}>
              <InputLabel id="select-perpage-label">Order By</InputLabel>
              <Select
                size="small"
                label="Order By"
                name="filter_order"
                id="select-order"
                labelId="select-order-label"
                onChange={this.handleChangeFilter}
                value={filter_order}
                sx={{ width: "100%" }}
              >
                {orderBy.map((item, idx) => {
                  return (
                    <MenuItem value={item.id} key={idx}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item md={2} xs={12}></Grid> */}
          <Grid item md={3} xs={12}>
            <Stack direction={"row"} spacing={2}>
              {!is_loading ? (
                <Button
                  ref={(button) => (this.buttonElement = button)}
                  variant="contained"
                  onClick={this.handleFilterSubmit}
                >
                  Filter
                </Button>
              ) : (
                <Typography className="btn-loading-filter">
                  HARAP SABAR...
                </Typography>
              )}
              <Button variant="contained" onClick={this.handleFilterClear}>
                Clear Filter
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Tables
          rows={rows}
          data={data}
          hasMore={hasMore}
          columns={this.columns}
        />
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getPackageAll: (data) => dispatch(getPackageAll(data)),
    deletePackage: (data) => dispatch(deletePackage(data)),
    // deleteVideo: (data) => dispatch(deleteVideo(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(packageAll))
);
