import axios from "axios";
import { BASE_URL, BASE_VERSION } from "../config";

export const getPackageAll = (payload) => {
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "package/all", {
        params: payload,
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
        validateStatus: () => true,
      })
      .then(function (response) {
        let payload = response.data;
        return payload;
      })
      .catch(function (error) {
        let payload = error;
        return payload;
      });
  };
};

export const addPackage = (payload) => {
  return (dispatch) => {
    return axios
      .post(
        BASE_URL + BASE_VERSION + "package",
        {
          name: payload.name,
          description: payload.description,
          qty_print: payload.qty_print,
          price: payload.price,
          is_active: payload.is_active ? 1 : 0,
        },
        {
          headers: {
            Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
          },
        }
      )
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        console.log(error);
        payload = error;
        return payload;
      });
  };
};

export const deletePackage = (payload) => {
  return (dispatch) => {
    return axios
      .delete(BASE_URL + BASE_VERSION + "package/" + payload.id, {
        params: payload,
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
        validateStatus: () => true,
      })
      .then(function (response) {
        let payload = response.data;
        return payload;
      })
      .catch(function (error) {
        let payload = error;
        return payload;
      });
  };
};

export const getPackage = (payload) => {
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "package/" + payload.slug, {
        params: payload,
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
        validateStatus: () => true,
      })
      .then(function (response) {
        let payload = response.data;
        return payload;
      })
      .catch(function (error) {
        let payload = error;
        return payload;
      });
  };
};

export const editPackage = (payload) => {
  return (dispatch) => {
    return axios
      .put(
        BASE_URL + BASE_VERSION + "package/" + payload.id,
        {
          name: payload.name,
          description: payload.description,
          qty_print: payload.qty_print,
          price: payload.price,
          is_active: payload.is_active ? 1 : 0,
        },
        {
          headers: {
            Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
          },
        }
      )
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        console.log(error);
        payload = error;
        return payload;
      });
  };
};