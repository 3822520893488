import React, { Component } from "react";
import { connect } from "react-redux";
import {
  // Link,
  withRouter,
} from "react-router-dom";

import $ from "jquery";
import { fabric } from "fabric";

import { addFrame, syncFrame } from "../redux/actions/frames";
import { getBrandAll } from "../redux/actions/brands";
import { getBoothSearch } from "../redux/actions/booths";
import { getCategoryAll } from "../redux/actions/categories";

// import { objectConfig, controlConfig } from "../utils/config";

import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";
// import LoadingButton from '@mui/lab/LoadingButton';
// import CircularProgressWithLabel from '@mui/material/CircularProgressWithLabel';

import image1 from "../assets/images/image-1.jpeg";
import image2 from "../assets/images/image-2.jpeg";
import image3 from "../assets/images/image-3.jpeg";
import image4 from "../assets/images/image-4.jpeg";
import image5 from "../assets/images/image-5.jpeg";
import image6 from "../assets/images/image-6.jpeg";

import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

class FrameAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canvas: null,
      brands: null,
      booths: [],
      selectAllBooth: false,
      boothKeys: [],
      brandid: [],
      category: [],
      id_frame: null,
      syncFreame: false,
      selectedBrand: [],
      selectedBrandId: [],
      selectedBooth: [],
      selectedID: [],
      progress: 0,
      uploading: false,
      activeObj: null,
      activeObjProp: null,
      placeholderImages: [image1, image2, image3, image4, image5, image6],
      frame: {
        slug: "",
        name: "",
        description: "",
        layout: null,
        frame_image: "",
        thumbnail_image: "",
        idbrand: 1,
        is_active: true,
        start_at: null,
        end_at: null,
        category_id: "1",
        category_id_update: "1",
        category_name: "All Frame",
      },
      err: null,
    };

    this.addImage = this.addImage.bind(this);
    this.removeImg = this.removeImg.bind(this);
    this.progressWork = this.progressWork.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deleteActiveObj = this.deleteActiveObj.bind(this);
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.handleChangeFrame = this.handleChangeFrame.bind(this);
  }

  deleteActiveObj(e) {
    var ctx = this;
    ctx.state.canvas.remove(ctx.state.canvas.getActiveObject());
    ctx.setState({
      activeObj: null,
      activeObjProp: null,
    });
  }

  handleSync = (e) => {
    e.preventDefault();
    if (this.state.boothKeys.length !== 0) {
      if (this.state.id_frame !== null) {
        const answer = window.confirm("Are you sure to Sync?");
        if (answer) {
          this.props
            .syncFrame({
              boothkeys: this.state.boothKeys,
              id_frame: this.state.id_frame,
            })
            .then((e) => {
              this.props.history.replace("/dashboard/frame");
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        alert("Frame not found");
      }
    } else {
      alert("Please select booth first !");
    }
  };

  removeImg(e) {
    e.preventDefault();
    var ctx = this;
    ctx.setState(
      {
        frame: {
          ...ctx.state.frame,
          [e.target.name]: "",
        },
      },
      function () {
        if (e.target.name === "frame_image") {
          ctx.state.canvas.setOverlayImage(
            null,
            ctx.state.canvas.renderAll.bind(ctx.state.canvas)
          );
        }
      }
    );
  }

  handleChangeFile(e) {
    let ctx = this;
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.onloadend = function () {
      let imagebase64 = reader.result;
      ctx.setState(
        {
          frame: {
            ...ctx.state.frame,
            [e.target.name]: imagebase64,
          },
        },
        function () {
          if (e.target.name === "frame_image") {
            ctx.state.canvas.setOverlayImage(
              null,
              ctx.state.canvas.renderAll.bind(ctx.state.canvas)
            );
            fabric.Image.fromURL(imagebase64, function (myImg) {
              let scaleI = ctx.state.canvas.width / myImg.width;
              let imgObject = myImg.set({
                left: 0,
                top: 0,
                scaleX: scaleI,
                scaleY: scaleI,
                selectable: false,
                opacity: 0.7,
              });
              imgObject.type = "cover";
              ctx.state.canvas.setOverlayImage(
                imgObject,
                ctx.state.canvas.renderAll.bind(ctx.state.canvas)
              );
            });
          }
        }
      );
    };
    reader.readAsDataURL(file);
  }

  progressWork(e) {
    this.setState({ progress: e });
  }

  handleSubmit(e) {
    e.preventDefault();
    let ctx = this;
    let frameDetail = [];
    ctx.state.canvas.getObjects().map((o) => {
      if (o.selectable !== false) {
        let objImg = {};
        objImg.idImage = o.idImage;
        objImg.type = o.type;
        objImg.isSquare = o.isSquare;
        objImg.left = o.left;
        objImg.top = o.top;
        objImg.scaleX = o.scaleX;
        objImg.scaleY = o.scaleY;
        objImg.width = o.width;
        objImg.height = o.height;
        objImg.angle = o.angle;
        frameDetail.push(objImg);
      }
      return false;
    });

    ctx.setState(
      {
        uploading: true,
        frame: {
          ...ctx.state.frame,
          layout: JSON.stringify(frameDetail),
          selectedBooth: ctx.state.selectedBooth,
          selectedBrand: ctx.state.selectedBrand,
          progress: this.progressWork,
        },
      },
      function () {
        ctx.props
          .addFrame(ctx.state.frame)
          .then((e) => {
            ctx.setState({ uploading: false });
            if (e.code === 200) {
              if (ctx.state.selectedBooth.length !== 0) {
                const answer = window.confirm(
                  "Do you want to continue frame sync?"
                );
                if (answer) {
                  ctx.setState({ id_frame: e.data.id }, function (e) {
                    this.buttonSync.click();
                    // ctx.handleSync;
                  });
                } else {
                  ctx.props.history.replace("/dashboard/frame");
                }
              } else {
                ctx.props.history.replace("/dashboard/frame");
              }
            } else {
              alert(e.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  }

  handleChangeFrame(e) {
    var ctx = this;
    var tmpVal = e.target.value;
    if (e.target.name === "is_active") {
      tmpVal = JSON.parse(e.target.checked);
    }
    ctx.setState({
      frame: {
        ...ctx.state.frame,
        [e.target.name]: tmpVal,
      },
    });

    // if (e.target.name === "idbrand") {
    //   this.props
    //     .getBoothSearch({ idbrand: tmpVal, is_active: 1 })
    //     .then((e) => {
    //       if (e.code === 200) {
    //         var dataBooth = e.data;
    //         ctx.setState(
    //           { booths: [], selectedBooth: [], selectedID: [] },
    //           function () {
    //             ctx.setState({ booths: dataBooth });
    //           }
    //         );

    //         // ctx.setState({booths:e.data, selectedBooth:[], selectedID:[]});
    //       } else {
    //         ctx.setState(
    //           { booths: [], selectedBooth: [], selectedID: [] },
    //           function () {
    //             ctx.setState({ booths: [] });
    //           }
    //         );
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }
  }

  handleChange(e) {
    var ctx = this;
    var tmpVal = e.target.value;
    // if(e.target.name !== "scaleX"){

    if (e.target.name === "isSquare") {
      tmpVal = JSON.parse(e.target.checked);
      if (tmpVal !== false) {
        ctx.state.activeObj.set({
          clipPath: new fabric.Rect({
            originX: "center",
            originY: "center",
            height: ctx.state.activeObj.height,
            width: ctx.state.activeObj.height + 300,
          }),
        });
        ctx.state.canvas.getActiveObject().isSquare = true;
      } else {
        ctx.state.activeObj.set({ clipPath: null });
        ctx.state.canvas.getActiveObject().isSquare = false;
      }
    } else {
      tmpVal = parseFloat(tmpVal);
      ctx.state.activeObj.set({ [e.target.name]: tmpVal }).setCoords();
    }
    ctx.state.canvas.renderAll();
    ctx.setState({
      activeObjProp: {
        ...ctx.state.activeObjProp,
        [e.target.name]: tmpVal,
      },
    });

    // }else{
    //     ctx.state.activeObj.set({
    //         scaleX:parseFloat(tmpVal),
    //         scaleY:parseFloat(tmpVal)
    //     }).setCoords();
    //     ctx.state.canvas.renderAll();
    //     ctx.setState({
    //         activeObjProp: {
    //             ...ctx.state.activeObjProp,
    //             [e.target.name]: parseFloat(tmpVal)
    //         }
    //     });
    // }
  }

  addImage(e) {
    let idimage = parseInt(e.target.dataset.idimage);
    let ctx = this;
    fabric.Image.fromURL(
      this.state.placeholderImages[idimage - 1],
      function (myImg) {
        let scale = 182.4 / myImg.width;
        let imgObject = myImg.set({
          left: 15.2,
          top: 45.6,
          scaleX: scale,
          scaleY: scale,
          angle: 0,
        });

        imgObject.type = "image";
        imgObject.isSquare = false;
        imgObject.idImage = idimage;
        imgObject.set({
          uniScaleTransform: true,
          lockUniScaling: false,
          borderColor: "#000000",
          cornerColor: "#000000",
          cornerSize: 6,
          transparentCorners: false,
        });
        imgObject.setControlsVisibility({
          // mb:false,
          // ml:false,
          // mt:false,
          // mr:false,
          // tl:false,
          // tr:false,
          // bl:false,
          mtr: false,
        });
        ctx.state.canvas.add(imgObject);
        ctx.state.canvas.renderAll();
      }
    );
  }

  initCanvas() {
    var ctx = this;
    var grid = 15.2;
    var activeObj = null;
    var canvas = new fabric.Canvas("canvas-generator");
    ctx.setState({ canvas: canvas });

    for (let i = 0; i < 426 / grid; i++) {
      canvas.add(
        new fabric.Line([i * grid, 0, i * grid, 635], {
          stroke: "#ccc",
          selectable: false,
        })
      );
    }
    for (let i = 0; i < 635 / grid; i++) {
      canvas.add(
        new fabric.Line([0, i * grid, 635, i * grid], {
          stroke: "#ccc",
          selectable: false,
        })
      );
    }

    canvas.add(
      new fabric.Line([213, 0, 213, 635], { stroke: "#000", selectable: false })
    );
    canvas.renderAll();

    canvas.on("mouse:down", function (options) {
      if (canvas.getActiveObject()) {
        $(".active-object-control").css("display", "flex");
        activeObj = options.target;
        // options.target.isSquare = false;
        ctx.setState({
          activeObj: options.target,
          activeObjProp: options.target,
        });

        // $(".input-data-left").val(activeObj.left);
        // $(".input-data-top").val(activeObj.top);
        // $(".input-data-scaleX").val(activeObj.scaleX);
        // $(".input-data-square").prop('checked', activeObj.isSquare);

        $(document).off("keydown");
        $(document).on("keydown", function (event) {
          let key = event.keyCode || event.charCode;
          let obj = activeObj;
          switch (key) {
            case 38:
              obj.top -= 1;
              ctx.setState({
                activeObjProp: {
                  ...ctx.state.activeObjProp,
                  top: obj.top,
                },
              });
              break;
            case 39:
              obj.left += 1;
              ctx.setState({
                activeObjProp: {
                  ...ctx.state.activeObjProp,
                  left: obj.left,
                },
              });
              break;
            case 40:
              obj.top += 1;
              ctx.setState({
                activeObjProp: {
                  ...ctx.state.activeObjProp,
                  top: obj.top,
                },
              });
              break;
            case 37:
              obj.left -= 1;
              ctx.setState({
                activeObjProp: {
                  ...ctx.state.activeObjProp,
                  left: obj.left,
                },
              });
              break;
            default:
              return true;
            // break;
          }
          activeObj.set(obj).setCoords();
          canvas.renderAll();
        });
      } else {
        ctx.setState({ activeObj: null, activeObjProp: null });
        activeObj = null;
        $(document).off("keydown");
      }
    });
    canvas.on("object:moving", function (options) {
      if (
        Math.round((options.target.left / grid) * 2) % 2 === 0 &&
        Math.round((options.target.top / grid) * 2) % 2 === 0
      ) {
        options.target
          .set({
            left: Math.round(options.target.left / grid) * grid,
            top: Math.round(options.target.top / grid) * grid,
          })
          .setCoords();
      }

      ctx.setState({
        activeObjProp: {
          ...ctx.state.activeObjProp,
          left: options.target.left,
          top: options.target.top,
        },
      });
    });

    canvas.on("object:rotating", function (options) {
      let angleTmp = Math.floor(options.target.angle);
      // if(angleTmp !== 0 || angleTmp !== 45 || angleTmp !== 90 || angleTmp !== 180 || angleTmp !== 270 || angleTmp !== 315){
      //     angleTmp = 0;
      //     options.target.set({
      //         angle: angleTmp
      //     }).setCoords();
      // }

      ctx.setState({
        activeObjProp: {
          ...ctx.state.activeObjProp,
          angle: angleTmp, //Math.floor(options.target.angle)
        },
      });
    });

    canvas.on("object:scaling", function (options) {
      let target = options.target;
      // console.log(target.scaleX);
      // console.log(target.scaleY);

      // let pointer = options.pointer;
      // let px = Math.round(pointer.x / grid) * grid;
      // let rx = (px - target.left) / target.width;
      // options.target.set({
      //     scaleX: rx,
      //     scaleY: rx
      // }).setCoords();
      // ctx.setState({
      //     activeObjProp: {
      //         ...ctx.state.activeObjProp,
      //         scaleX: rx
      //     }
      // });

      ctx.setState({
        activeObjProp: {
          ...ctx.state.activeObjProp,
          scaleX: target.scaleX,
          scaleY: target.scaleY,
        },
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeObj !== this.state.activeObj) {
      if (this.state.activeObj !== null) {
        disableScroll();
      } else {
        enableScroll();
      }
    }
  }

  componentWillUnmount() {
    enableScroll();
  }

  componentDidMount() {
    var ctx = this;
    this.props
      .getBrandAll()
      .then((e) => {
        if (e.code === 200) {
          ctx.setState({ brands: e.data });
        } else {
          console.log("Something Error");
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // this.props
    //   .getBoothSearch({ idbrand: this.state.frame.idbrand, is_active: 1 })
    //   .then((e) => {
    //     if (e.code === 200) {
    //       ctx.setState({ booths: e.data });
    //     } else {
    //       console.log("Something Error");
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    this.props
      .getCategoryAll({ is_active: 1 })
      .then((response) => {
        if (response.code === 200) {
          this.setState({ category: response.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.initCanvas();
  }

  handleClick = (event) => {
    if (event.target.checked) {
      this.setState(
        { selectedBooth: this.state.booths, selectAllBooth: true },
        function () {
          let boothKeys = [];
          this.state.selectedBooth.forEach((el) => {
            boothKeys.push(el.boothkey);
          });
          this.setState({ boothKeys });
        }
      );
    } else {
      this.setState({
        selectedBooth: [],
        selectAllBooth: false,
        boothKeys: [],
      });
    }
  };

  handleChangeStartDate = (event, e) => {
    const ctx = this;
    this.setState({
      frame: {
        ...ctx.state.frame,
        start_at: event,
      },
    });
  };

  handleChangeEndDate = (event, e) => {
    const ctx = this;
    this.setState({
      frame: {
        ...ctx.state.frame,
        end_at: event,
      },
    });
  };

  render() {
    var frameContainer =
      this.state.frame.frame_image !== "" ? (
        <Box>
          <img
            alt="canvas-preview-img"
            width="140px"
            height="auto"
            className="canvas-preview-image"
            style={{
              display: "block",
              marginTop: "5px",
              marginBottom: "15px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src={this.state.frame.frame_image}
          />
          <Button
            fullWidth
            size="small"
            color="error"
            className="input-image-file"
            name="frame_image"
            variant="contained"
            // onKeyDown={(e) => e.keyCode === 32}
            sx={{ marginBottom: "0px" }}
            onClick={this.removeImg}
          >
            Delete Frame
          </Button>
        </Box>
      ) : (
        <Box>
          <Button
            fullWidth
            size="small"
            className="input-image-file"
            component="label"
            variant="contained"
            // onKeyDown={(e) => e.keyCode === 32}
            sx={{ marginBottom: "0px" }}
          >
            Upload Frame
            <input
              type="file"
              accept="image/*"
              hidden
              name="frame_image"
              onChange={this.handleChangeFile}
            />
          </Button>
        </Box>
      );

    var thumbnailContainer =
      this.state.frame.thumbnail_image !== "" ? (
        <Box>
          <img
            alt="canvas-thumbnail-img"
            width="140px"
            height="auto"
            className="canvas-preview-image"
            style={{
              display: "block",
              marginTop: "5px",
              marginBottom: "15px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src={this.state.frame.thumbnail_image}
          />
          <Button
            fullWidth
            size="small"
            color="error"
            className="input-image-file"
            name="thumbnail_image"
            variant="contained"
            onKeyDown={(e) => e.keyCode === 32}
            sx={{ marginBottom: "0px" }}
            onClick={this.removeImg}
          >
            Delete Thumbnail
          </Button>
        </Box>
      ) : (
        <Button
          fullWidth
          size="small"
          className="input-image-file"
          component="label"
          variant="contained"
          onKeyDown={(e) => e.keyCode === 32}
          sx={{ marginBottom: "0px" }}
        >
          Upload Thumbnail
          <input
            type="file"
            accept="image/*"
            hidden
            name="thumbnail_image"
            onChange={this.handleChangeFile}
          />
        </Button>
      );

    return (
      <Container className="main-panel summary">
        <Grid container className="header-panel" alignItems="center" mb={1}>
          <Grid item md={6} sx={{ justifyContent: "flex-start" }}>
            <Typography variant="h6">
              {!this.props.title ? "" : this.props.title}
            </Typography>
          </Grid>
          {/* <Grid item md={6} sx={{textAlign:"right"}}>
                        <Button className="btn-restart-canvas">Restart</Button>
                    </Grid> */}
        </Grid>
        <Paper>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Box
                sx={{ width: "426px", marginLeft: "auto", marginRight: "auto" }}
              >
                <Box sx={{ marginBottom: "10px" }}>
                  <canvas
                    id="canvas-generator"
                    width="426"
                    height="635"
                    style={{ width: "426px", height: "635px" }}
                  ></canvas>
                </Box>
                <Grid container className="add-image-container" spacing={1}>
                  <Grid item md={4}>
                    <Button
                      size="small"
                      fullWidth
                      variant="contained"
                      onClick={this.addImage}
                      data-idimage={1}
                    >
                      Image 1
                    </Button>
                  </Grid>
                  <Grid item md={4}>
                    <Button
                      size="small"
                      fullWidth
                      variant="contained"
                      onClick={this.addImage}
                      data-idimage={2}
                    >
                      Image 2
                    </Button>
                  </Grid>
                  <Grid item md={4}>
                    <Button
                      size="small"
                      fullWidth
                      variant="contained"
                      onClick={this.addImage}
                      data-idimage={3}
                    >
                      Image 3
                    </Button>
                  </Grid>
                  <Grid item md={4}>
                    <Button
                      size="small"
                      fullWidth
                      variant="contained"
                      onClick={this.addImage}
                      data-idimage={4}
                    >
                      Image 4
                    </Button>
                  </Grid>
                  <Grid item md={4}>
                    <Button
                      size="small"
                      fullWidth
                      variant="contained"
                      onClick={this.addImage}
                      data-idimage={5}
                    >
                      Image 5
                    </Button>
                  </Grid>
                  <Grid item md={4}>
                    <Button
                      size="small"
                      fullWidth
                      variant="contained"
                      onClick={this.addImage}
                      data-idimage={6}
                    >
                      Image 6
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item md={6} alignItems="left">
              <Grid
                container
                spacing={1}
                justifyContent="center"
                sx={{ paddingLeft: "5px", paddingRight: "5px" }}
              >
                <Grid item md={6}>
                  <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
                    Select Cover
                  </Typography>
                  {thumbnailContainer}
                </Grid>
                <Grid item md={6}>
                  <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
                    Select Frame
                  </Typography>
                  {frameContainer}
                </Grid>
                {this.state.activeObj !== null ? (
                  <Grid item md={12}>
                    <Box
                      sx={{
                        borderRadius: "5px",
                        backgroundColor: "#fafafa",
                        padding: "10px",
                        marginTop: "10px",
                        border: "1px solid #d9d9d9",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ marginBottom: "10px" }}
                      >
                        Active Object
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item md={6}>
                          <TextField
                            size="small"
                            fullWidth
                            type="number"
                            inputProps={{ step: "1" }}
                            placeholder="1.0"
                            label="Position X"
                            name="left"
                            onChange={this.handleChange}
                            value={this.state.activeObjProp.left}
                          ></TextField>
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            size="small"
                            fullWidth
                            type="number"
                            inputProps={{ step: "1" }}
                            placeholder="1.0"
                            label="Position Y"
                            name="top"
                            onChange={this.handleChange}
                            value={this.state.activeObjProp.top}
                          ></TextField>
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            size="small"
                            fullWidth
                            type="number"
                            inputProps={{ step: "0.0001" }}
                            placeholder="1.0"
                            label="Scale X"
                            name="scaleX"
                            onChange={this.handleChange}
                            value={this.state.activeObjProp.scaleX}
                          ></TextField>
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            size="small"
                            fullWidth
                            type="number"
                            inputProps={{ step: "0.0001" }}
                            placeholder="1.0"
                            label="Scale Y"
                            name="scaleY"
                            onChange={this.handleChange}
                            value={this.state.activeObjProp.scaleY}
                          ></TextField>
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            size="small"
                            fullWidth
                            type="number"
                            inputProps={{ step: "45" }}
                            placeholder="1.0"
                            label="Rotation"
                            name="angle"
                            onChange={this.handleChange}
                            value={this.state.activeObjProp.angle || ""}
                          ></TextField>
                          <FormHelperText>
                            Rotasi : 0, 45, 90, 270, 315
                          </FormHelperText>
                        </Grid>
                        {/* <Grid item md={6}>
                                                <FormControlLabel 
                                                    control={<Switch checked={this.state.activeObjProp.isSquare}/>}
                                                    label="Square Mode"
                                                    value={this.state.activeObjProp.isSquare}
                                                    name="isSquare"
                                                    onChange={this.handleChange}
                                                    sx={{marginBottom:"20px"}}
                                                />
                                            </Grid> */}
                        <Grid item md={12}>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={this.deleteActiveObj}
                            sx={{ marginBottom: "10px" }}
                          >
                            Delete Object
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ) : (
                  ""
                )}
                <Grid item md={12}>
                  <Box
                    sx={{
                      borderRadius: "5px",
                      backgroundColor: "#fafafa",
                      padding: "10px",
                      marginTop: "10px",
                      border: "1px solid #d9d9d9",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ marginBottom: "10px" }}
                    >
                      Data Frame
                    </Typography>
                    <Box component="form" onSubmit={this.handleSubmit}>
                      <FormGroup>
                        {this.state.brands !== null ? (
                          <Autocomplete
                            multiple
                            size="small"
                            id="checkboxes-tags-demo"
                            // ChipProps={{ color: "error" }}
                            onChange={(event, newValue) => {
                              this.setState({ selectedBrand: newValue }, () => {
                                let brandid = [];
                                newValue.forEach((el) => {
                                  brandid.push(el.id);
                                });
                                this.setState({ brandid }, function () {
                                  const ctx = this;
                                  ctx.setState(
                                    {
                                      booths: [],
                                      selectedBooth: [],
                                      selectedID: [],
                                    },
                                    async function () {
                                      const boothSearch =
                                        await ctx.props.getBoothSearch({
                                          idbrand: ctx.state.brandid,
                                          is_active: 1,
                                        });
                                      if (boothSearch.code === 200) {
                                        boothSearch.data.map((item) => {
                                          ctx.setState((prevState) => ({
                                            booths: [...prevState.booths, item],
                                          }));
                                        });
                                      }
                                    }
                                  );
                                });
                              });
                            }}
                            autoHighlight
                            disableCloseOnSelect
                            defaultValue={this.state.selectedBrandId.map(
                              (item) => this.state.booths[item]
                            )}
                            options={this.state.brands}
                            value={this.state.selectedBrand}
                            filterSelectedOptions
                            sx={{ marginBottom: "5px" }}
                            getOptionLabel={(option) => option?.name}
                            renderOption={(props, option, { selected }) => {
                              return (
                                <li
                                  {...props}
                                  style={{ padding: "0px" }}
                                  data-idbrand={option.id}
                                >
                                  <Checkbox
                                    icon={
                                      <CheckBoxOutlineBlankIcon fontSize="small" />
                                    }
                                    checkedIcon={
                                      <CheckBoxIcon fontSize="small" />
                                    }
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.name}
                                </li>
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Brand"
                                placeholder="Brand"
                                helperText="Pilih Brand"
                              />
                            )}
                          />
                        ) : (
                          // <FormControl sx={{ minWidth: 120 }}>
                          //   <InputLabel id="select-brand-label">
                          //     Brand
                          //   </InputLabel>
                          //   <Select
                          //     size="small"
                          //     labelId="select-brand-label"
                          //     id="select-brand"
                          //     name="idbrand"
                          //     value={this.state.frame.idbrand}
                          //     label="Brand"
                          //     onChange={this.handleChangeFrame}
                          //     sx={{ marginBottom: "10px" }}
                          //   >
                          //     {this.state.brands.map((item) => {
                          //       return (
                          //         <MenuItem value={item.id} key={item.id}>
                          //           {item.name}
                          //         </MenuItem>
                          //       );
                          //     })}
                          //   </Select>
                          // </FormControl>
                          <Typography>Loading...</Typography>
                        )}

                        <TextField
                          size="small"
                          fullWidth
                          type="text"
                          placeholder="Frame Name"
                          label="Name"
                          name="name"
                          required
                          sx={{ marginBottom: "10px" }}
                          onChange={this.handleChangeFrame}
                          value={this.state.frame.name}
                        ></TextField>

                        <TextField
                          size="small"
                          fullWidth
                          type="text"
                          placeholder="frame-slug"
                          label="Slug"
                          name="slug"
                          required
                          sx={{ marginBottom: "10px" }}
                          onChange={this.handleChangeFrame}
                          value={this.state.frame.slug}
                        ></TextField>

                        <TextField
                          size="small"
                          fullWidth
                          type="text"
                          placeholder="Frame Description"
                          label="Description"
                          name="description"
                          sx={{ marginBottom: "10px" }}
                          multiline={true}
                          rows={3}
                          onChange={this.handleChangeFrame}
                          value={this.state.frame.description}
                        ></TextField>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateTimePicker
                            inputFormat="yyyy-MM-dd HH:mm"
                            ampm={false}
                            label="Date Start"
                            name="start_at"
                            type="date"
                            value={this.state.frame.start_at}
                            onChange={this.handleChangeStartDate}
                            renderInput={(params) => (
                              <TextField
                                sx={{ marginBottom: "10px" }}
                                size="small"
                                {...params}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateTimePicker
                            inputFormat="yyyy-MM-dd HH:mm"
                            ampm={false}
                            label="Date End"
                            name="end_at"
                            type="date"
                            value={this.state.frame.end_at}
                            onChange={this.handleChangeEndDate}
                            renderInput={(params) => (
                              <TextField
                                sx={{ marginBottom: "10px" }}
                                size="small"
                                {...params}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>

                        <Autocomplete
                          freeSolo
                          name="category_id"
                          id="free-solo-2-demo"
                          size="small"
                          disableClearable
                          onChange={(e, v) => {
                            this.setState({
                              frame: {
                                ...this.state.frame,
                                category_id: v.id,
                                category_id_update: v.id,
                                category_name: v.label,
                              },
                            });
                          }}
                          value={this.state.frame.category_name || ""}
                          options={this.state.category.map((option) => ({
                            id: option.id,
                            label: option.name,
                          }))}
                          sx={{ minWidth: 200 }}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                label="Category"
                                InputProps={{
                                  ...params.InputProps,
                                  type: "search",
                                }}
                              />
                            );
                          }}
                        />

                        <FormControlLabel
                          control={
                            <Switch checked={this.state.frame.is_active} />
                          }
                          label="Active"
                          name="is_active"
                          value={this.state.frame.is_active}
                          onChange={this.handleChangeFrame}
                          sx={{ marginBottom: "10px", marginLeft: "5px" }}
                        />
                        {this.state.booths !== null ? (
                          <>
                            <Autocomplete
                              multiple
                              size="small"
                              id="checkboxes-tags-demo"
                              ChipProps={{ color: "error" }}
                              onChange={(event, newValue) => {
                                if (this.state.selectAllBooth) {
                                  this.buttonElement.click();
                                }

                                this.setState(
                                  { selectedBooth: newValue },
                                  () => {
                                    let boothKeys = [];
                                    newValue.forEach((el) => {
                                      boothKeys.push(el.boothkey);
                                    });
                                    this.setState({ boothKeys });
                                  }
                                );
                              }}
                              autoHighlight
                              disableCloseOnSelect
                              defaultValue={this.state.selectedID.map(
                                (item) => this.state.booths[item]
                              )}
                              options={this.state.booths}
                              value={this.state.selectedBooth}
                              filterSelectedOptions
                              sx={{ marginBottom: "5px" }}
                              getOptionLabel={(option) => option?.name}
                              renderOption={(props, option, { selected }) => {
                                return (
                                  <li
                                    {...props}
                                    style={{ padding: "0px" }}
                                    data-idbooth={option.id}
                                  >
                                    <Checkbox
                                      icon={
                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                      }
                                      checkedIcon={
                                        <CheckBoxIcon fontSize="small" />
                                      }
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {option.name}
                                  </li>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Booths"
                                  placeholder="Booths"
                                  helperText="Pilih Booth"
                                />
                              )}
                            />
                            <FormGroup>
                              <FormControlLabel
                                sx={{ marginBottom: "15px" }}
                                ref={(button) => (this.buttonElement = button)}
                                onClick={this.handleClick}
                                control={<Checkbox />}
                                label="Select All Booth"
                              />
                            </FormGroup>
                          </>
                        ) : (
                          "Loading"
                        )}

                        {/* variant="determinate" value={parseInt(this.state.progress)} */}
                        {this.state.uploading !== false ? (
                          <Box sx={{ width: "100%", textAlign: "center" }}>
                            <CircularProgress />
                          </Box>
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            disabled={
                              this.props.permissions.indexOf("create frame") !==
                              -1
                                ? false
                                : true
                            }
                          >
                            Create Frame
                          </Button>
                        )}
                        {this.state.syncFreame !== false ? (
                          <Box
                            sx={{
                              width: "100%",
                              textAlign: "center",
                              marginTop: "20px",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleSync}
                            sx={{ marginTop: "20px" }}
                            ref={(button) => (this.buttonSync = button)}
                            disabled={
                              this.props.permissions.indexOf("sync booth") !==
                              -1
                                ? false
                                : true
                            }
                          >
                            Sync Frame
                          </Button>
                        )}
                      </FormGroup>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }
}

var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    e.preventDefault();
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener(
    "test",
    null,
    Object.defineProperty({}, "passive", {
      get: function () {
        supportsPassive = true;
      },
    })
  );
} catch (e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent =
  "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

// call this to Disable
function disableScroll() {
  // window.addEventListener("DOMMouseScroll", preventDefault, false); // older FF
  // window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  // window.addEventListener("touchmove", preventDefault, wheelOpt); // mobile
  window.addEventListener("keydown", preventDefaultForScrollKeys, false);
}

// call this to Enable
function enableScroll() {
  // window.removeEventListener("DOMMouseScroll", preventDefault, false);
  // window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  // window.removeEventListener("touchmove", preventDefault, wheelOpt);
  window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
}

function mapDispatchToProps(dispatch) {
  return {
    addFrame: (data) => dispatch(addFrame(data)),
    getBrandAll: (data) => dispatch(getBrandAll(data)),
    getBoothSearch: (data) => dispatch(getBoothSearch(data)),
    syncFrame: (data) => dispatch(syncFrame(data)),
    getCategoryAll: (data) => dispatch(getCategoryAll(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FrameAdd)
);
