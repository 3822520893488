import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Tables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      data: [],
      hasMore: false,
    };
  }

  componentDidMount() {
    this.setState({
      rows: this.props.rows,
      data: this.props.data,
      hasMore: this.props.hasMore,
    });
  }

  componentDidUpdate(prevProv, prevState) {
    if (
      prevProv.rows !== this.props.rows ||
      prevProv.data !== this.props.data ||
      prevProv.hasMore !== this.props.hasMore
    ) {
      this.setState({
        rows: this.props.rows,
        data: this.props.data,
        hasMore: this.props.hasMore,
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      rows: [],
      data: [],
      hasMore: false,
    });
  }

  render() {
    const { rows, data, hasMore } = this.state;
    const { loadFunc } = this.props;

    return (
      <Paper>
        <TableContainer sx={{ maxHeight: 570 }}>
          <InfiniteScroll
            // id="infinite-scroll-component"
            height={500}
            dataLength={data.length}
            next={loadFunc}
            hasMore={hasMore}
            loader={
              <Typography
                id="load-data"
                sx={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  padding: "15px",
                }}
              >
                Loading...
              </Typography>
            }
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {rows.map((el, idx) => (
                    <TableCell key={idx} align={el.align}>
                      {el.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((el, idx) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                      {rows.map((row, i) => {
                        const value = el[row.value];
                        const key = el;
                        return (
                          <TableCell
                            key={i}
                            align={row.align}
                          >
                            {this.props.columns(row.value, value, key, idx)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </InfiniteScroll>
        </TableContainer>
      </Paper>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    //logout: userData => dispatch(logout(userData))
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tables));
