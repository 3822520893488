import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { addPackage } from "../redux/actions/package";

class PackageAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      price: "",
      qty_print: "",
      is_active: true,
    };
  }

  handleChange = (e) => {
    let tmpVal = e.target.value;
    if (e.target.name === "is_active") {
      tmpVal = JSON.parse(e.target.checked);
    }
    this.setState({
      [e.target.name]: tmpVal,
    });
  };

  handleSubmit = async (e) => {
    const { name, description, qty_print, price, is_active } = this.state;
    e.preventDefault();
    const answer = window.confirm("Are you sure?");
    if (answer) {
      const request = await this.props.addPackage({
        name,
        description,
        qty_print,
        price,
        is_active,
      });
      if (request.code === 200) {
        this.props.history.replace("/dashboard/package");
      } else {
        alert(request.message);
      }
    }
  };

  render() {
    const { title } = this.props;
    return (
      <Container className="main-panel booth">
        <Grid container className="header-panel" alignItems="center" mb={1}>
          <Grid item md={6} xs={6} sx={{ justifyContent: "flex-start" }}>
            <Typography variant="h6">{!title ? "" : title}</Typography>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Paper
            component="form"
            className="panel-container-box"
            onSubmit={this.handleSubmit}
          >
            <FormGroup>
              <FormLabel sx={{ marginTop: "12px", marginBottom: "5px" }}>
                Name <span className="required">*</span>
              </FormLabel>
              <TextField
                size="small"
                id="input-name"
                name="name"
                label=""
                helperText="Nama Paket"
                placeholder="Nama Paket"
                value={this.state.name}
                onChange={this.handleChange}
                required
                sx={{ marginBottom: "20px" }}
              />
              <FormLabel sx={{ marginTop: "12px", marginBottom: "5px" }}>
                Description <span className="required">*</span>
              </FormLabel>
              <TextField
                size="small"
                id="input-description"
                name="description"
                label=""
                helperText="Deskripsi Paket"
                placeholder="Deskripsi Paket"
                value={this.state.description}
                onChange={this.handleChange}
                multiline
                rows={3}
                sx={{ marginBottom: "20px" }}
              />
              <FormLabel sx={{ marginTop: "12px", marginBottom: "5px" }}>
                Price <span className="required">*</span>
              </FormLabel>
              <TextField
                size="small"
                id="input-price"
                name="price"
                label=""
                type="number"
                helperText="Harga Paket"
                placeholder="Harga Paket"
                value={this.state.price}
                onChange={this.handleChange}
                sx={{ marginBottom: "20px" }}
              />
              <FormLabel sx={{ marginTop: "12px", marginBottom: "5px" }}>
                Qty Print <span className="required">*</span>
              </FormLabel>
              <TextField
                size="small"
                id="input-qty_print"
                name="qty_print"
                label=""
                type="number"
                helperText="Kuantitas Print"
                placeholder="Kuantitas Print"
                value={this.state.qty_print}
                onChange={this.handleChange}
                sx={{ marginBottom: "20px" }}
              />
              <FormControlLabel
                control={<Switch defaultChecked />}
                label="Active"
                name="is_active"
                value={this.state?.is_active}
                onChange={this.handleChange}
                sx={{ marginBottom: "20px", marginLeft: "5px" }}
              />
              <Button
                type="submit"
                variant="contained"
                disabled={
                  this.props.permissions.indexOf("create package") !== -1
                    ? false
                    : true
                }
              >
                Create
              </Button>
            </FormGroup>
          </Paper>
        </Box>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addPackage: (data) => dispatch(addPackage(data)),
    // addCoupon: (data) => dispatch(addCoupon(data)),
    // getUserAll: (data) => dispatch(getUserAll(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PackageAdd)
);
